









import { Component, Vue } from 'vue-property-decorator';
import { NFTUser } from '@/NFTUser'
import * as utils from '@/utils';
import * as utils_auth from '@/utils_auth';
import * as utils_tracker from '@/utils_tracker';

@Component({
  name: 'MagicURL',
  components: { }
})
export default class MagicURLView extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public code: string
  public path: string

  public get loading(): boolean { return this.$store.state.loading }
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }

  // public functions available to the html template

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    utils_tracker.page(this.$options.name)

    // get code from URL
    this.code = this.$route.params.code

    // handle new kind of MagicURL that has path in the url rather than in DB
    // get optional path as URL param (in case where server wanted to save MagicURL db entries in exchange for longer URLs)
    this.path = this.$route.query.path as string

    // send to server to get authentication credentials and path to redirect to
    await this.fetchData()
  }

private async fetchData(): Promise<void> {

    this.$store.commit('setLoading', { loading: true });
    try {
        let url: string = utils.getURLprefix() + '/api/auth/useMagic'
        url += `?code=${this.code}`
        const response: any = await utils.makeGetCall(url);

        // error check
        if (response.data.error) {
          this.$store.commit('setLoading', { loading: false });
          try { await this.$router.push({ name: 'home', params: { title: 'Error', msg: response.data.error }}) } catch (Error) {}
          return
        }

        // ok, we should get path back or use the one in the original URL
        const path: string = response.data.path || this.path
 
        // if got user and sessionId back, then log them in and send them to right path
        if (response.data.user && response.data.sessionId) {
          const user: NFTUser = new NFTUser(response.data.user)
          utils_auth.saveAuthStuffToStoreAndLocalStorage(user, response.data.sessionId)

          utils_tracker.alias(user)
          // utils_tracker.identify(user)

          try { await this.$router.push({ path }) } catch (Error) {}
        }

        // if we got only user back, then this means user was already logged in, so no need to update sessionId, but might as well refresh user
        if (response.data.user && !response.data.sessionId) {
          const user: NFTUser = new NFTUser(response.data.user)
          utils_auth.saveAuthStuffToStoreAndLocalStorage(user, null)
          try { await this.$router.push({ path }) } catch (Error) {}
        }

        // wacky case -- if we get neither back, then this means the existing logged in user was not the right user, but can continue with current sessionId and just go to path (hopefully he has permission)
        if (!response.data.user && !response.data.sessionId) {
          try { await this.$router.push({ path }) } catch (Error) {}
        }

    } catch (error) {
      utils.toast(this, 'Error', 'Could not load url')
      if (this.$store.state.devmode) console.log(error);
    }
    this.$store.commit("setLoading", { loading: false });


  }

}

