



















import { Component, Vue } from 'vue-property-decorator';
import { NFTUser, emailTypes } from '@/NFTUser'
import * as utils from '@/utils';
import * as utils_auth from '@/utils_auth';
import * as utils_tracker from '@/utils_tracker';

@Component({
  name: 'Unsubscribe',
  components: { }
})
export default class UnsubscribeView extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public code: string
  public emailType: emailTypes
  public modalTitle: string = 'Unsubscribe from emails'
  public modalShow: boolean = true
  public modalSize: string = "md"
  public unsubscribingUser: NFTUser
  public invalidCode: boolean = false

  public get loading(): boolean { return this.$store.state.loading }
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }

  public getModalTitle(): string {
    if (this.unsubscribingUser) return `Unsubscribe (${this.unsubscribingUser.email})`
    return 'Unsubscribe from emails'
  }

  public getDescriptionFromType(): string {
    return NFTUser.getDescriptionFromEmailType(this.emailType)
  }

  // public functions available to the html template
  public async onCancel() {
    this.modalShow = false
    try { await this.$router.push({ name: 'home' }) } catch (Error) {}
  }
  public async onOK() {
    this.$store.commit('setLoading', { loading: true });
    try {
        let url: string = utils.getURLprefix() + '/api/auth/unsubscribe'
        url += `?code=${this.code}&type=${this.emailType}&execute=true`
        const response: any = await utils.makeGetCall(url);

        // error check
        if (response.data.error) {
          this.$store.commit('setLoading', { loading: false });
          try { await this.$router.push({ name: 'home', params: { title: 'Error', msg: response.data.error }}) } catch (Error) {}
          this.modalShow = false
          return
        }

        if (response.data.user) {
          this.unsubscribingUser = new NFTUser(response.data.user)
          try { await this.$router.push({ name: 'home', params: { title: 'Success', msg: `${this.unsubscribingUser.email} was successfully unsubscribed` }}) } catch (Error) {}
        }

    } catch (error) {
      utils.toast(this, 'Error', 'Could not unsubscribe')
      if (this.$store.state.devmode) console.log(error);
    } finally {
      this.$store.commit("setLoading", { loading: false });
      this.modalShow = false
    }
  }

  public async checkCodeAndGetUser() {
    this.$store.commit('setLoading', { loading: true });
    try {
        let url: string = utils.getURLprefix() + '/api/auth/unsubscribe'
        url += `?code=${this.code}&type=${this.emailType}`
        const response: any = await utils.makeGetCall(url);

        // error check
        if (response.data.error) {
          this.$store.commit('setLoading', { loading: false });
          try { await this.$router.push({ name: 'home', params: { title: 'Error', msg: response.data.error }}) } catch (Error) {}
          this.invalidCode = true
          this.modalShow = false
          return
        }

        if (response.data.user) {
          this.unsubscribingUser = new NFTUser(response.data.user)
        }

    } catch (error) {
      utils.toast(this, 'Error', 'Could not unsubscribe')
      if (this.$store.state.devmode) console.log(error);
      this.invalidCode = true
      this.modalShow = false
      return

    } finally {
      this.$store.commit("setLoading", { loading: false });
    }
  }


  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    utils_tracker.page(this.$options.name)

    // get code from URL
    this.code = this.$route.params.code

    // get emailType from URL
    this.emailType = this.$route.query.type as any

    await this.checkCodeAndGetUser()



  }

}

