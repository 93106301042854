

























































import { Component, Vue, Watch } from 'vue-property-decorator';
import * as utils from '@/utils';
import * as utils_auth from '@/utils_auth';
import * as utils_tracker from '@/utils_tracker';

@Component({
  name: 'PrivacyPolicy',
  components: {}
})
export default class PrivacyPolicyView extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public get loading(): boolean { return this.$store.state.loading }
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }

  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`);

    utils_tracker.page(this.$options.name)

  }

}

