











import { Component, Vue } from 'vue-property-decorator';
import { NFTUser } from '@/NFTUser'
import * as utils from '@/utils';
import * as utils_auth from '@/utils_auth';
import * as utils_tracker from '@/utils_tracker';
import ModalWrapperComponent from '@/components/ModalWrapperComponent.vue';             // @ is an alias to /src


@Component({
  name: 'Confirm',
  components: { ModalWrapperComponent }
})
export default class ConfirmView extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public emailVerificationCode: string

  public get loading(): boolean { return this.$store.state.loading }
  public get isAuthenticated(): boolean { return this.$store.getters.isAuthenticated }

  // public functions available to the html template

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    utils_tracker.page(this.$options.name)

    // get code from URL
    this.emailVerificationCode = this.$route.params.code

    // send to server to verify this user's email address and see if registration needs to be completed still (invited user)
    await this.fetchData()
  }

private async fetchData(): Promise<void> {

    this.$store.commit('setLoading', { loading: true });
    try {
        let url: string = utils.getURLprefix() + '/api/auth/confirmCode'
        url += `?code=${this.emailVerificationCode}`
        const response: any = await utils.makeGetCall(url);

        // error check
        if (response.data.error) {
          this.$store.commit('setLoading', { loading: false });
          try { await this.$router.push({ name: 'home', params: { title: 'Error', msg: response.data.error }}) } catch (Error) {}
          return
        }

        // if got user and sessionId back, then log them in as if it were a magic url
        if (response.data.user && response.data.sessionId) {
          const user: NFTUser = new NFTUser(response.data.user)
          utils_auth.saveAuthStuffToStoreAndLocalStorage(user, response.data.sessionId)

          utils_tracker.alias(user)

          try { await this.$router.push({ name: 'home', params: { title: 'Success', msg: response.data.message }}) } catch (Error) {}
        }

        // if we get neither back, then this means the existing logged in user can continue with current sessionId and do nothing
        if (!response.data.user && !response.data.sessionId) {
          try { await this.$router.push({ name: 'home', params: { title: 'Success', msg: response.data.message }}) } catch (Error) {}
        }

    } catch (error) {
      utils.toast(this, 'Error', 'Could not confirm code')
      if (this.$store.state.devmode) console.log(error);
    } finally {
      this.$store.commit("setLoading", { loading: false });
    }


  }

}

